<template lang="pug">
.article-card-actions
      .article-card-actions__action.article-card-actions__favorite
        FavoriteBlock(
          :user="user"
          :favorite="favorite"
          :folders="folders"
          :isShowFavoriteFolderName="true"
          :color="'#969698'"
          :hoverColor="'#000000'"
          :colorText="'#5c5c5c'"
          :colorTextHover="'#000000'"

          @favorite-delete="onFavoriteDeleteEmit"
          @favorite-create-folder-and-add="onFavoriteCreateFolderAndAddEmit"
          @favorite-create-folder-and-transfer="onFavoriteCreateFolderAndTransferEmit"
          @favorite-transfer-to-folder="onFavoriteTransferToFolderEmit"
          @favorite-add-to-folder="onFavoriteAddToFolderEmit"
          @favorite-deferred="onFavoriteDeferredEmit"
          @open-favorite-menu="upZIndexRecipeLayoutContent"
          @close-favorite-menu="downZIndexRecipeLayoutContent"
        )

      ShareButton.article-card-actions__action(
        buttonText="Поделиться"
        :shareImgUrl="coverUrl"
        :shareTitle="title"
        :shareLink="url"
        :type="'recipe'"
        :offsets="{ top:8,bottom:8 }"
      )

      .article-card-actions__action.article-card-actions__comments-count(
        v-if="commentsEnabled"
      )
        TheCommentsCrumb(
          :link="getRelativeLink(url) + '#comments'"
          :openCommentsInNewTab="openCommentsInNewTab"
          :count="commentsCount"
          @onClickCommentIcon="onClickCommentIcon"
        )
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { isMobileOnly } from 'mobile-device-detect';
  import FavoriteBlock from '@/components/lh-ui/FavoriteBlock/FavoriteBlock.vue';
  import TheLink from '~/components/TheLink.vue';
  import TheCommentsCrumb from '~/components/ArticleCardActionsCrumbs/TheCommentsCrumb.vue';
  import ShareButton from '~/components/ShareButton.vue';
  import { isHomeLink, getRelativeLink, getFullUrl } from '~/utils';

  import { isRecipesMainPage } from '~/utils/router';

  export default defineNuxtComponent({
    name: 'ArticleCardActions',

    components: {
      TheLink,
      FavoriteBlock,
      TheCommentsCrumb,
      ShareButton,
    },

    inject: {
      eventPositionShareBlock: {
        default() {
          return () => '';
        },
      },
    },

    props: {
      gtmEventPosition: {
        type: String as PropType<string>,
        default: 'top',
      },
      url: {
        type: String as PropType<string>,
        required: true,
      },
      coverUrl: {
        type: String as PropType<string | null>,
        default: null,
      },
      commentsCount: {
        required: true,
        type: Number as PropType<number>,
      },
      commentsEnabled: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
      title: {
        type: String as PropType<string>,
        required: true,
      },
      user: {
        type: Object as PropType<IUser | null>,
        default: null,
      },
      favorite: {
        required: false,
        type: Object as PropType<IFavoriteItem | null>,
        default: null,
      },
      folders: {
        required: true,
        type: Object as PropType<IFolders>,
      },
      scrollToComments: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
      openCommentsInNewTab: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      type: {
        type: String as PropType<'article' | 'recipe'>,
        default: 'article',
      },
    },
    computed: {
      recipeAnalyticsElement(): string {
        const route = useRoute();

        if (isRecipesMainPage(route.name)) {
          return 'Главная';
        } else if (this.type === 'recipe') {
          return 'Рецепт';
        } else {
          return 'Таксономия';
        }
      },
    },
    methods: {
      isHomeLink,
      getRelativeLink,

      // TODO
      // костыль - повышаем zIndex чтобы перекрыть кнопку фильтра #1phmbzy
      // при открытии меню добавления в избранное
      upZIndexRecipeLayoutContent() {
        if (isMobileOnly) {
          const topHeaderContainer = document.getElementById('recipes-layout-content');
          if (topHeaderContainer) {
            topHeaderContainer.classList.add('recipes-layout__content--up');
          }
        }
      },

      // TODO
      // костыль - понижаем zIndex чтобы перекрыть кнопку фильтра #1phmbzy
      // пр изакрытии меню добавления в избранное
      downZIndexRecipeLayoutContent() {
        if (isMobileOnly) {
          const topHeaderContainer = document.getElementById('recipes-layout-content');
          if (topHeaderContainer) {
            topHeaderContainer.classList.remove('recipes-layout__content--up');
          }
        }
      },

      onClickCommentIcon(event: Event): void {
        const eventElements: Record<string, string> = {
          top: 'внутренние_верхняя иконка комментариев',
          bottom: 'внутренние_нижняя иконка комментариев',
          archives: 'Архивы_иконка комментариев',
          'recipe-top': 'внутренние_верхняя иконка комментариев',
          'recipe-filters': 'Архивы_иконка комментариев',
          'recipe-main': 'Главная_иконка комментариев',
        };
        const element = eventElements[this.gtmEventPosition];

        this.$sendYandexMetrika({
          level1: 'Комментарии_переход к комментариям',
          level4: getFullUrl(this.url),
          level6: element,
        });

        if (this.scrollToComments) {
          event.preventDefault();
          const comments: HTMLDivElement | null = document.querySelector('#comments');
          if (comments) {
            comments.scrollIntoView();
          }
        }
        this.$emit('to-comments');
      },

      onFavoriteDeferredEmit(deferredAction: Function, ctx: any) {
        this.$emit('favorite-deferred', deferredAction, ctx);
      },
      onFavoriteAddToFolderEmit(folder: IFolderItem) {
        this.$emit('favorite-add-to-folder', folder);
      },
      onFavoriteTransferToFolderEmit(favorite: IFavoriteItem, folder: IFolderItem) {
        this.$emit('favorite-transfer-to-folder', favorite, folder);
      },
      onFavoriteCreateFolderAndTransferEmit(nameFolder: string, favorite: IFavoriteItem): void {
        this.$emit('favorite-create-folder-and-transfer', nameFolder, favorite);
      },
      onFavoriteCreateFolderAndAddEmit(nameFolder: string): void {
        this.$emit('favorite-create-folder-and-add', nameFolder);
      },
      onFavoriteDeleteEmit(favorite: IFavoriteItem): void {
        this.$emit('favorite-delete', favorite);
      },
    },
  });
</script>

<style lang="scss" scoped>
  $deepShareIcon: ':deep(.share-button__icon)';
  $deepShareText: ':deep(.share-button__text)';

  .article-card-actions {
    display: flex;
    align-items: center;

    &__action {
      display: flex;
      padding: 10px 0 10px 0;
      margin: -10px 0 -10px 0;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      min-width: 0;

      svg {
        height: 24px;
        width: 24px;
        stroke: #969698;
        stroke-width: 2px;
        fill: none;
      }
      @include hover {
        #{$deepShareText},
        span {
          color: black;
        }
        #{$deepShareIcon},
        svg {
          stroke: black;
        }
      }

      #{$deepShareText},
      span {
        @include fontSmallTextBtn;

        color: #5c5c5c;
        margin-left: 2px;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__favorite {
      overflow: hidden;

      .lh-button-icon__text {
        color: #5c5c5c;
      }

      @include tablet {
        overflow: inherit;
      }
    }

    &__comments-count {
      flex-shrink: 0;
    }
  }

  // https://youtrack.lifehacker.ru/issue/LH-1128
  .article-note_empty + .article__actions {
    margin-top: 16px;
    @include desktop {
      margin-top: 0;
    }
  }
</style>
