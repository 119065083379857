// @ts-ignore
import type { PropOptions } from 'vue';
import { pushBadge } from '../Pusher/websockets-functions';
import { isRecipesMainPage } from '~/utils/router';
import { shareInNewWindow } from '~/utils/shareInNewWindow';
import type { ShareOptions } from '~/utils/shareInNewWindow';
import { textToClipboard } from '@devhacker/shared/utils/clipboard';
import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
import vkIcon from '~/assets/images/vk.svg?raw';
import twitterIcon from '~/assets/images/twitter.svg?raw';
import telegramIcon from '~/assets/images/telegram.svg?raw';
import viberIcon from '~/assets/images/viber.svg?raw';
import pinterestIcon from '~/assets/images/pinterest.svg?raw';
import orangeCheckIcon from '~/assets/images/orangeCheck.svg?raw';
import linkIcon from '~/assets/images/link.svg?raw';

type ShareMethod = {
  url?: string;
  params?: Record<string, string>;
  title: string;
  class?: string;
  name: string;
  icon: string;
};

export default defineNuxtComponent({
  name: 'ShareBlock',

  components: {
    SvgAsset,
  },

  inject: {
    eventPositionShareBlock: {
      default() {
        return () => '';
      },
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    } as PropOptions<string>,
    link: {
      type: String,
      required: true,
    } as PropOptions<string>,
    imageUrl: {
      type: String,
      default: '',
    } as PropOptions<string>,
    type: {
      type: String,
      default: 'article',
    } as PropOptions<'article' | 'recipe' | 'article-reaction'>,
  },

  data() {
    return {
      copyFinished: false as boolean,
    };
  },

  computed: {
    classShareBlock(): Record<string, boolean> {
      return {
        [`share-block--${this.type}`]: true,
      };
    },

    recipeAnalyticsElement(): string {
      if (isRecipesMainPage(useRoute().name)) {
        return 'Главная';
      } else if (this.type === 'recipe') {
        return 'Рецепт';
      } else {
        return 'Таксономия';
      }
    },

    shareMethods(): ShareMethod[] {
      return [
        {
          title: this.copyFinished ? 'Скопировано' : 'Скопировать ссылку',
          name: 'copy',
          class: this.copyFinished ? 'finished' : undefined,
          icon: this.copyFinished ? orangeCheckIcon : linkIcon,
        },
        {
          url: 'http://vk.com/share.php',
          params: {
            url: this.link,
          },

          title: 'Vkontakte',
          name: 'vkontakte',
          icon: vkIcon,
        },
        {
          url: 'https://twitter.com/intent/tweet',
          params: {
            text: this.title,
            url: this.link,
            hashtags: this.type === 'recipe' ? 'времяесть,рецепты' : 'лайфхакер,лайфхаки,лайфхак',
            via: 'ru_lh',
          },

          title: 'Twitter',
          name: 'twitter',
          icon: twitterIcon,
        },
        {
          url: 'https://t.me/share/url',
          params: {
            text: `${this.title}`,
            url: this.link,
          },
          title: 'Telegram',
          name: 'telegram',
          icon: telegramIcon,
        },
        {
          url: 'viber://forward',
          params: {
            text: `${this.title} \n${this.link}`,
            url: this.link,
          },

          title: 'Viber',
          name: 'viber',
          icon: viberIcon,
        },
        {
          url: 'https://www.pinterest.com/pin/create/button/',
          params: {
            url: this.link,
            image_url: this.imageUrl,
          },

          title: 'Pinterest',
          name: 'pinterest',
          icon: pinterestIcon,
        },
      ];
    },
  },

  methods: {
    async share(shareMethod: ShareMethod): Promise<void> {
      this.$sendYandexMetrika({
        level1: 'Социальная_активность',
        level4: shareMethod.name
      })

      if (shareMethod.name === 'copy') {
        await textToClipboard(this.link);
        this.copyFinished = true;

        this.$emit('copy-clicked');
      } else {
        const { ok } = await shareInNewWindow(shareMethod as ShareOptions);
        if (!ok && shareMethod.name === 'viber') {
          pushBadge({
            content:
              'Не удалось поделиться ссылкой. Проверьте, установлен ли Viber на вашем устройстве.',
            isError: true,
          });
          return;
        }
      }

      const { trackSocialInteraction } = await import('@snowplow/browser-plugin-site-tracking');

      trackSocialInteraction({
        action: shareMethod.name === 'copy' ? 'copy' : 'share',
        network: shareMethod.name,
        target: this.link,
      });
    },
  },
});
