<template>
  <ContextMenu
    :active="isShareBlockVisible"
    :offset-y="8"
    :offset-x="-12"
    type="hover"
    class="share-button__container"
    @update:active="onUpdateShareBlockVisibility"
  >
    <div class="share-button__handler" />
    <SvgAsset
      class="share-button__icon"
      :content="shareIcon"
    />
    <span
      v-if="buttonText"
      class="share-button__text"
    >
      {{ buttonText }}
    </span>

    <template #context>
      <ShareBlock
        :image-url="shareImgUrl"
        :title="shareTitle"
        :link="shareLink"
        :type="type"
      />
    </template>

    <template #mobile>
      <ShareBlock
        :image-url="shareImgUrl"
        :title="shareTitle"
        :link="shareLink"
        :type="type"
        @copy-clicked="isShareBlockVisible = false"
      />
    </template>
  </ContextMenu>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { isMobile } from 'mobile-device-detect';
  import ContextMenu from '@devhacker/shared/components/ContextMenu.vue';
  import { isTouchDevice } from '~/utils';
  import ShareBlock from '~/components/ShareBlock/ShareBlock.vue';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import ShareIcon from '~/assets/images/share-icon.svg?raw';

  export default defineNuxtComponent({
    components: {
      ShareBlock,
      ContextMenu,
      SvgAsset,
    },

    inject: {
      eventPositionShareBlock: {
        default() {
          return () => '';
        },
      },
    },

    props: {
      buttonText: {
        type: String as PropType<string>,
        default: '',
      },
      shareImgUrl: {
        type: String as PropType<string>,
        default: '',
      },
      shareTitle: {
        type: String as PropType<string>,
        default: '',
      },
      shareLink: {
        type: String as PropType<string>,
        default: '',
      },
      type: {
        type: String as PropType<'article' | 'recipe' | 'article-reaction'>,
        default: 'article-reaction',
      },
    },

    data() {
      return {
        isShareBlockVisible: false as boolean,
      };
    },

    computed: {
      shareIcon() {
        return ShareIcon;
      },
    },

    methods: {
      onUpdateShareBlockVisibility(isVisible: boolean): void {
        this.isShareBlockVisible = isVisible;
      },

      handleMouseEnter(): void {
        const isTouch = isTouchDevice();
        if (isTouch) {
          return undefined;
        }
        this.isShareBlockVisible = true;
      },

      handleMouseLeave(): void {
        this.isShareBlockVisible = false;
      },

      handleClick(): void {
        if (isMobile && 'share' in navigator) {
          this.$emit('webShare', 'Открытие меню');
          // No ts type for navigator share
          (navigator as any)
            .share({
              url: this.shareLink,
              title: this.shareTitle,
            })
            .then(() => this.$emit('webShare', 'Переход в приложение'))
            .catch(() => this.$emit('webShare', 'Закрытие меню'));

          return;
        }

        this.isShareBlockVisible = !this.isShareBlockVisible;
      },
    },
  });
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<style lang="scss" scoped>
  .share-button {
    &__container {
      position: relative;
      flex-shrink: 0;

      @include hover {
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          height: 8px;
          width: 320px;
          z-index: 1;
        }
      }
    }

    &__handler {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &__icon {
      width: 24px;
      height: 24px;
      stroke: #969698;
      stroke-width: 2px;
      fill: none;
    }
  }
</style>
