import { getSubId } from '~/utils';
import random from 'lodash/random';

export const checkAccessTokenAndDoAction = async (_vm: any, action: any): Promise<void> => {
  if (!getSubId()) {
    const XUserId = JSON.parse(localStorage.getItem('XUserId') || '0');

    if (!XUserId) {
      const generateXUserId = random(-2000000000, -1000000000);
      localStorage.setItem('XUserId', JSON.stringify(generateXUserId));
    }
  }
  return await action();
};
