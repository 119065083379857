export type ShareOptions = {
  url: string;
  params?: Record<string, string>;
  width?: number;
  height?: number;
};

export async function shareInNewWindow(options: ShareOptions) {
  // URLSearchParams использует другой формат кодирования, из-за чего некоторые символы не декодируются на платформах
  // Пример: viber заменяет пробел на +
  const params = Object.entries(options.params || {}).map(
    ([key, value]) => `${key}=${encodeURIComponent(value)}`,
  );

  const fullUrl = `${options.url}?${params.join('&')}`;

  const popWidth = options.width || 600;
  const popHeight = options.height || 480;
  const left = window.innerWidth / 2 - popWidth / 2 + window.screenX;
  const top = window.innerHeight / 2 - popHeight / 2 + window.screenY;
  const popParams = `scrollbars=no,width=${popWidth},height=${popHeight},top=${top},left=${left}`;
  const newWindow = window.open(fullUrl, '', popParams);
  newWindow?.focus();

  await new Promise((resolve) => setTimeout(resolve, 6500));
  try {
    // пробуем прочитать origin
    const _ = newWindow?.location.origin;
    newWindow?.close();
    return Promise.resolve({ ok: false });
  } catch {
    return Promise.resolve({ ok: true });
    // если тут ошибка, то значит в новом окне все загрузилось и браузер
    // запрещает нам читать location.origin из соображений безопасности
  }
}
